.contact-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
}

.contact-title {
    color: white;
    font-family: 'Bangla MN';
    font-size: calc(2vw + 20px);
    text-align: center;
    position: absolute;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%);
}

.contact-text-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 5vh;
    width: 100%;
}

.contact-text2 {
    color: white;
    font-family: 'Bangla MN';
    background-color: rgba(0,0,0,0.5);
    padding: 35px;
    margin-left: 10vw;
    margin-right: 5vw;
}

.contact-text {
    font-size: 1rem;
    line-height: 20px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    padding-bottom: 10px;
}


.contact-text-svg {
    vertical-align: bottom;
    margin-right: 10px;
}

@media (max-width: 580px) {
    .contact-title {
        top: 12vh
    }

    .contact-text  {
        font-size: 0.7rem;
        line-height: 20px;
        font-weight: bold;
        text-align: left;
        cursor: pointer;
        padding-bottom: 10px;
    }

    .contact-text2 {
        padding: 25px;
    }

    .contact-text-wrapper {
        bottom: 14vh;
    }
}

.pointer {
    cursor: pointer;
}