.page-about {
    overflow: hidden;
    height: 100vh;
}
.about-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
}

.about-title {
    color: black;
    font-family: 'Bangla MN';
    font-size: calc(2vw + 20px);
    text-align: center;
    position: absolute;
    top: 5vh;
    left: 50%;
    transform: translateX(-50%);
}

.about-text-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 5vh;
}

.about-text {
    color: black;
    text-align: center;
    font-family: 'Bangla MN';
    font-size: 1rem;
    line-height: 20px;
    margin-left: 10vw;
    margin-right: 5vw;
    /* font-weight: bold; */
    background-color: rgba(255,255,255,0.5);
    padding: 10px
    
}

@media (max-width: 580px) {
    .about-title {
        top: 12vh
    }

    .about-text  {
        font-size: 0.8rem;
        line-height: 20px;
        font-weight: bold;
        /* text-align: left; */
        cursor: pointer;
        padding-bottom: 10px;
    }

    .about-text-wrapper {
        bottom: 18vh;
    }
}