@font-face {
  font-family: 'Bangla MN';
  src: url(./assets/fonts/Bangla/Bangla.ttf) format('opentype');
}

@font-face {
  font-family: 'Playfair';
  src: url(./assets/fonts/PlayFair/playfairdisplay-regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Playfair Bold';
  src: url(./assets/fonts/PlayFair/playfairdisplay-bold.ttf) format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
}

@function crem($font-size, $global-font-size: 14px) {
  @if unitless($font-size) {
    $font-size: $font-size * 1px;
  }

  @return ($font-size/$global-font-size) * 1rem;
}

.font {
  &-center {
    text-align: center;
  }

  &-5xl {
    font-size: crem(48);
  }

  &-4xl {
    font-size: crem(40);
  }

  &-3xl {
    font-size: crem(32);
  }

  &-2xl {
    font-size: crem(28);
  }

  &-xl {
    font-size: crem(24);
  }

  &-lg {
    font-size: crem(20);
  }

  &-2md {
    font-size: crem(18);
  }

  &-md {
    font-size: crem(16);
  }

  &-2normal {
    font-size: crem(15);
  }

  &-normal {
    font-size: crem(14);
  }

  &-sm {
    font-size: crem(12);
  }
}