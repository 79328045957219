.App-ImageInput {

}

.App-ImageInput .photo-file-input {
    color: transparent;
}
.App-ImageInput .photo-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.App-ImageInput .photo-file-input::before {
    content: 'Select your photo';
    color: black;
    display: inline-block;
    background-color: white;
    border-radius: 0px;
    padding: 8px 8px 3px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    font-family: Bangla MN;
    font-weight: 400;
    line-height: 20px;
    margin-left: -5px;
}

.App-ImageInput  .photo-file-input:hover::before {
    border-color: black;
}

.App-ImageInput .photo-file-input:active {
    outline: 0;
}

.App-ImageInput .photo-file-input:active::before {
    background: black; 
}
  