html {
    font-size: 14px;

    @media (min-width: 500px) {
        font-size: 20px;
    }

}

.academy-container {
    .svg-rwd {
        max-width: 500px;
        width: 80%;
    }

    font-family: Playfair;
    letter-spacing: 1px;

    overflow-x: hidden;

    .section-container {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .section-container15 {
        height: 150vh;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mustard {
        background-color: #d8baa3;
    }

    .white {
        background-color: white;
    }

    .main-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 20px;

        @media (min-width: 1000px) {
            padding: 0px 120px;
        }

        img {
            max-width: 500px;
        }

        p {
            margin-top: 60px;
            font-weight: 600;
            letter-spacing: 2.5px;
        }
    }

    .aboutus-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 20px;

        p {
            text-align: justify;
            font-size: 14px
        }

        @media (min-width: 1000px) {
            padding: 0px 120px;

            p {
                font-size: 20px;
            }
        }



        .photos-container {
            display: flex;
            max-height: 50vh;
            margin-top: 40px;

            &>img {
                max-width: 20%;
                object-fit: cover;
            }
        }
    }

    .offer-container {
        padding: 0 100px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0px 20px;
        flex-direction: column;

        p {
            font-size: 14px;
        }

        &-inner {
            display: flex;
            flex-direction: column;
            // margin-right: 100px;

            &-list {
                max-width: 80vw;
                margin-top: 4vw;
            }
        }

        @media (min-width: 750px) {
            padding: 0px 120px;
            flex-direction: row;

            p {
                font-size: 18px;
                text-align: left;
            }

            &-inner {
                display: flex;
                flex-direction: column;
                margin-right: 100px;

                &-list {
                    max-width: 40vw;
                    margin-top: 4vw;
                }
            }
        }


        .photos-container {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            height: 130vh;
            position: relative;


            img {
                object-fit: cover;
                max-height: 30vh;
            }
        }

        .photos-container-row {
            display: flex;
            justify-content: space-around;
            position: relative;

            img {
                object-fit: cover;
                max-height: 30vh;
                max-width: 30vw;
            }
        }
    }

    .offer2-container {
        padding: 0 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        width: 90%;

        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: space-around;

            &-list {
                max-width: 40vw;
            }
        }

        @media (min-width: 1000px) {
            padding: 0px 120px;
        }

        p {
            text-align: left;
        }

        .photos2-container {
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;

            img {
                object-fit: cover;
                max-height: 40vh;
                margin-bottom: 20px;
            }
        }

    }



    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;

        @media (min-width: 1000px) {
            padding: 0px 120px;
        }

        p {
            align-self: flex-start;
            text-align: left;
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .schedule-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 20px;

        @media (min-width: 1000px) {
            padding: 0px 120px;
        }

        p {
            font-size: 20px;
        }

        img {
            margin-top: 50px;
            width: 250px;
            cursor: pointer;
        }
    }

    .faq-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0px 20px;
        font-size: 20px;

        @media (min-width: 1000px) {
            padding: 0px 120px;
        }

        .inner-cointaner {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            p {
                z-index: 2;
            }
        }

        img {
            margin-top: 50px;
            max-height: 80vh;
            width: 100%;
            object-fit: cover;
        }
    }

    .offer-star {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);

        .offer-star-contaier {
            position: relative;

            .offer-paint {
                position: absolute;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            p {
                position: absolute;
                width: 150px;
                font-size: 15px;
            }
        }
    }
}