.join-screen {
    background-color: black;
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    position: relative;
  }
  
.join-page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 80vh;
    // flex-direction: column
}
.join-form-wrapper {
    display: flex;

    // flex-direction: column/
}
.join-form-column {
    margin: 0 20px
}
.join-title {
    color: white;
    font-family: 'Bangla MN';
    font-size: 40px;
    margin-bottom: 5vh;
    margin-top: 15vh;
    text-align: center;
    // overflow-y: scroll
}

@media (max-width: 1070px) {
  .join-form-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .join-screen {
    // overflow-y: scroll;
  }

}

.join-screen-loader-description {
  color: white;
  font-family: 'Bangla MN';
  font-size: 40px;
  margin-top: 20px;
}