.App-PrivacyPolicy {
    /* height: 180px; */
    width: 310px;
    /* min-width: 250px; */
    /* background-color: white; */
    color: white;
    font-size: 11px;
    overflow: scroll;
    font-family: Bangla MN;
    padding: 2px;
    line-height: 12px;
    text-align: justify;
    margin: 5px 0 5px 0;
}

.App-PrivacyPolicy p {
    margin-bottom: 10px;
}