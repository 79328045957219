// @font-face {
//   font-family: 'Horizon';
//   src: local('Horizon'), url(./assets/fonts/Horizon/Horizon.otf) format('opentype');
// }

// @font-face {
//   font-family: 'Horizon-italic';
//   src: local('Horizon-italic'), url(./assets/fonts/Horizon/HorizonItalic.otf) format('opentype');
// }

// @font-face {
//   font-family: 'Lato';
//   src: local('Lato'), url(./assets/fonts/Lato/Lato-Regular.ttf) format('opentype');
// }


$color-white: white;
$color-black: black;
$border-white-big: 2px solid white;
$border-white-small: 1px solid white;
$border-black-big: 2px solid black;
$border-black-small: 1px solid black;

a:link {
  text-decoration: inherit;
  color: inherit;
  
}

a:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

.App {
  text-align: center;
  // background-color: #282c34;
  background-color: black;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  // touch-action: none;
  // -ms-touch-action: none;
}

.App-router {
  height: 100vh;
  position: relative;
}

// @media(max-width: 450px) {
//   .App-router {
//     padding-top: 10px;
//     height: 100vh;
//     position: relative;
//   }
// }



a {
  text-decoration: none;
}

.App-Logo-Text {
  position: relative;
  pointer-events: none;
  user-select: none;
}
.App-Logo {
  font-family: Bangla MN;
  color: white;
  white-space: nowrap;
  // white-space: pre;

  .font-color-black {
    color: $color-black;
  }

  .font-color-white {
    color: $color-white;
  }
  .line-color-top-left--white-small {
    border-top: $border-white-small;
    border-left: $border-white-small;
  }

  .line-color-top-left--black-small {
    border-top: $border-black-small;
    border-left: $border-black-small;
  }

  .line-color-top-left--white-big {
    border-top: $border-white-big;
    border-left: $border-white-big;
  }

  .line-color-top-left--black-big {
    border-top: $border-black-big;
    border-left: $border-black-big;
  }


  .line-color-bottom-right--white-small {
    border-bottom: $border-white-small;
    border-right: $border-white-small;
  }

  .line-color-top-right--black-small {
    border-bottom: $border-black-small;
    border-right: $border-black-small;
  }

  .line-color-bottom-right--white-big {
    border-bottom: $border-white-big;
    border-right: $border-white-big;
  }

  .line-color-bottom-right--black-big {
    border-bottom: $border-black-big;
    border-right: $border-black-big;
  }

  .line-color-bottom-right--black-small {
    border-bottom: $border-black-small;
    border-right: $border-black-small;
  }




  .App-logo-text--big {
    font-size: 80px;
  }
   
  .App-logo-text--small {
    font-size: 20px;
  }

  .App-logo-subtext--big {
    margin-top: 5px;
    font-size: 40px;
  }

  .App-logo-subtext--small {
    margin-top: 2px;
    font-size: 10px;
    text-align: center;
  }
  
  .App-logo-corner-top-left--big {
    width: 50px;
    position: absolute;
    height: 40px;
    // border-top: 2px solid white;
    // border-left: 2px solid white;
    top: -27px;
    left: -11px;
  }

  .App-logo-corner-top-left--small {
    width: 13px;
    position: absolute;
    height: 10px;
    // border-top: 1px solid white;
    // border-left: 1px solid white;
    top: -8px;
    left: -4px;
  }

  
  .App-logo-corner-bottom-right--big {
    width: 50px;
    position: absolute;
    height: 40px;
    // border-bottom: 2px solid white;
    // border-right: 2px solid white;
    top: 27px;
    left: 73px;
  }
  .App-logo-corner-bottom-right--small {
    width: 12px;
    position: absolute;
    height: 10px;
    // border-bottom: 1px solid white;
    // border-right: 1px solid white;
    top: 6px;
    left: 18px;
  }
}

.loader {
  background-color: rgba(128,128,128,0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    
  }
}



.MainMenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  top: 0px;
  z-index: 5;
  overflow: hidden;
  // touch-action: none;
  // -ms-touch-action: none;

  // .App-Logo-position {
  //   position: absolute;
  //   left: 20px;
  //   top: 30px;
  // }
}

.MainMenu {
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 48px;
    font-family: Bangla MN;
    color: white;
  }
  
  ul:hover {
    /* color: transparent; */
  }
  
  ul li {
    margin-bottom: 35px;
  }
  
  ul li a{
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
  
  ul li a:hover {
    -webkit-text-stroke: 1px white;
    // color: #282c34;
    color: black;
  }

  .horizontal {
    display: flex;
    justify-content: space-between;
  }
  
  @media (max-width: 450px) {
    .horizontal {
      font-size: 20px;
      flex-direction: column;
      // padding-top: 10px;
    }

    ul li {
      font-size: 25px;
    }
  }

  @media(min-width: 450px) {
    .horizontal {
      padding-top: 25px;
      font-size: 20px;
    }
  }

  // .horizontal {
  //   font-size: 20px;
  //   display: flex;
  //   justify-content: space-between;
  //   padding-top: 25px
  // }
}


.on {
  opacity: 1;
  
  /* transition-duration: 0ms, 200ms;
  transition-property: visibility, opacity;
  transition-delay: 0ms;
  */
} 

.off {
  opacity: 0;
  
  /* transition-duration: 200ms, 0ms;
  transition-property: visibility, opacity;
  transition-delay: 0ms, 200ms; */
}

.onTop {
  z-index:3;
}

.default { opacity: 0; visibility: hidden; top: -10px; transition-duration: 200ms, 200ms, 0; transition-property: opacity, top, visibility; transition-delay: 0, 0, 200ms; } 
.hidden { opacity: 1; visibility: visible; top: 0px; transition-duration: 200ms, 200ms, 0; transition-property: opacity, top, visibility; transition-delay: 200ms, 200ms, 0; }


.App-header {
  // background-color: #282c34;
  background-color:black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
} */


#MenuIconAnimated {
  position: absolute;
  right: 30px;
  top: 30px;
}

.App-gallery-screen {
  background-color: white;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;

  // .App-Logo-position {
  //   top: 30px;
  //   left: 20px;
  //   position: absolute;
  // }

  .App-gallery-content {
    width: 80%;
    height: 90%;   

    .App-gallery-content-inner {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
      gap: 16px;
      justify-content: center;
      // padding: initial;
    }
    .App-gallery-picture:last-child{
      margin-bottom: 30px;
    }
    
    .App-gallery-picture {
      margin-left: 25px;
      margin-left: 25px;
      display: block;

      img {
        object-fit: cover;
      }

      img:hover {
        // -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        // filter: grayscale(100%);
        opacity: 0.8;
      }

      .App-gallery-text {
        text-align: center;
        font-family: Bangla MN;
        margin-top: 15px;
        font-size: 14px;
      }

    }
  }
}


.App-SocialSection {
  position: fixed;
  z-index: 10;
  left: 40px;
  display: flex;
  bottom: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 30px;
  height: 75px;

  @media (max-width: 450px) {
    left: 20px;
  }

  .facebook {
    width: 21px;
    cursor: pointer;
  }

  .instagram {
    cursor: pointer;
  }
}

.App-ModelDetailsScreen {
  background-color: white;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: relative;

  // .App-Logo-position {
  //   top: 30px;
  //   left: 20px;
  //   position: absolute;
  // }

  .App-ModelInfo {
    width:100%;

  }

  .App-ModelName {
    font-size: 40px;
    font-family: Bangla MN;
    color: #524343;
    text-align: center;
  }


  .App-Counter {
    font-size: 20px;
    font-family: Bangla MN;
    color: #524343;
  }
  .App-ModelSize {
    font-family: Bangla MN;
    color: #524343;
    text-align: center;
    font-size: 20px;
    // text-decoration: underline;
    cursor: pointer;

  }

  @media (max-width: 500px) {
    .App-ModelName {
      padding-top: 0px;
      font-size: 20px;
    }
 
  }

  @media (min-width: 500px) {
    .App-ModelName {
      padding-bottom: 35px;
      padding-top:35px;
    }
  }
}
.App-Carousel {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-top: 70px;
  width: 100vw;
  // margin-bottom: 100px;
  .App-Carousel-Pictures {
    display: flex;
    // margin-top: 20px;
  }

  .ChevronLeft, .ChevronRight {
    margin: 0px 310px;
  }

  img {
    position: absolute;
  }
}


.App-ModelMainPageScreen {
  background-color: white;
  // height: 80vh;
  width: 100vw;
  overflow: hidden;
  height: 100%;

  a {
    cursor: pointer;
  }

  // .App-Logo-position {
  //   top: 30px;
  //   left: 20px;
  //   position: absolute;
  // }

  .App-ModelTitle {
    font-size: 40px;
    font-family: Bangla MN;
    padding-top: 35px;
    padding-bottom: 20px;
    text-align: center;
  }

  @media (max-width: 580px) {
    .App-ModelTitle {
      margin-top: 70px;
    }
  }

  img {
    width: 100%;
    // max-height: 80vh;
    object-fit: cover;
    // max-width: 1920px;
  }

  .App-ModelMainPage-text-section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    flex-direction: column;
    
    p {
      color: black;
      font-size: 25px;
      font-family: Bangla MN;
      margin-top: 30px;
    }
  }
}

@media (max-width: 580px) {
  .App-ModelMainPageMenu {
    flex-direction: column;
    justify-content: center;
    font-size: 30px;

    p {
      margin-top: 15px;
    }
  }

  .App-BackButton { 
    display: none;
  }
}

@media (min-width: 580px) {
  .App-ModelMainPageMenu {
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
    font-size: 20px;
  }
}




.App-ModelMainPageMenu {
  display: flex;
  font-family: Bangla MN;

  margin-top: 30px;
  // transition: 2s;
  cursor: pointer;
  
  p:hover {
    border-bottom: 2px solid black;
  }

  .active {
    opacity: 1;
  }

  .disabled {
    text-decoration: line-through;
    cursor: default;
    &:hover {
      border-bottom: unset !important;
    }
  }
  
}

.App-BackButton {
  width: 50px;
  height: 100px;
  position: relative;
  cursor: pointer;

  .BackButton-top {
    transform: rotate(45deg);
    background-color: black;
    height: 3px;
    width: 40px;
    position: absolute;
    top: 43px;
    left: 12px;
  }

  .BackButton-bottom {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    background-color: black;
    transform: rotate(-45deg);
    height: 3px;
    width: 40px;
    position: absolute;
    top: 16px;
    left: 13px;

  }
}

.App-ModelDimensions {
  width: 100%;
  font-family: Bangla MN;
  font-size: 16px;
  display: flex;

  .ModelDimensions-dimensions {
    margin: 20px 10vw 0px 10vw;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ModelDimensions-units {
    margin: 0px 7vw;
  }

  .ModelDimensions-units {
    text-align: center;
  }

  .ModelDimensions-units-underline {
    height: 1px;
    width: 100%;
    background-color: black;
    margin-bottom: 7px;
  }
}

.ChevronRight, .ChevronLeft {
  height: 40px;
  width: 40px;
  fill: #524343;
  cursor: pointer;
  
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

.notouch {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.carousel {
  z-index: -1;
  height: "70vh";
  max-width: 630px; 
  object-fit: "cover";
}


.page-main-wrapper {
  background-color: black;
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.App-Logo-position {
    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
}

@media (min-width: 1000px) {
  .App-Logo-position {
    position: fixed;
  }
  #MenuIconAnimated {
    position: fixed;
  }

}

// .Logo-Animated {
// $anime-time: 8s;
// $box-size: 10vw;
// $font-size: 7vw;
// $clip-distance: .05;
// $clip-size: $box-size * (1 + $clip-distance * 2);
// $path-width: 0.3vw;
// display: flex;
// align-items: center;

// $main-color: black;

// %full-fill {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// .bb {
//   @extend %full-fill;
//   width: $box-size;
//   height: $box-size;
//   margin: auto;
//   // background: grey no-repeat 50% / 70% rgba(#000, .1);
//   color: $main-color;
//   font-size: $font-size;
//   font-family: Bangla MN;
//   line-height: $font-size;
//   // padding-top: 30px;
  
//   // padding-top:10px;
//   // box-shadow: inset 0 0 0 1px rgba($main-color, .5);
//   display: flex;
//   .text {
//     align-self: flex-end;
//   }
//   &::before,
//   &::after {
//     @extend %full-fill;
//     content: '';
//     z-index: -1;
//     margin: -1 * $clip-distance * 100%;
//     box-shadow: inset 0 0 0 $path-width; 
//     animation: clipMe $anime-time linear infinite;
//   }

//   &::before {
//     animation-delay: $anime-time * -.5;
//   }

//   
//   &:hover {
//     &::after,
//     &::before {
//       background-color: rgba(#f00, .3);
//     }
//   }

// }

// @keyframes clipMe {
//   0%, 100% {clip: rect(0px, $clip-size, $path-width, 0px); }
//   25% {clip: rect(0px, $path-width, $clip-size, 0px); }
//   50% {clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px); }
//   75% {clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width); }
// }

// }