.App-ButtonSendMail {
    width: 320px;
    height: 35px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.App-ButtonSendMail p {
    font-family: Bangla MN;
    line-height: 35px;
    height: 35px;
    padding-top: 2px;
}