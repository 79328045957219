

.form-input-description {
    font-size: 13px;
    color: white;
    font-family: Bangla MN;
    padding-left: 2px
}
.form-input-formInput {
    margin-bottom: 15px
}
.form-input-input {
    padding: 5px;
    /* // backgroundColor: #979797; */
    border: 1px solid black;
    color: black;
    font-size: 12px;
    width: 310px;
}
.form-input-inputError {
    color: #CC4545;
    margin-top: 10px;
    font-family: Arial;
    /* // fontFamily: 'Bangla MN'; */
    font-size: 12px;
    letter-spacing: 1px
}
